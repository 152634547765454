import React, { useEffect, useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import totalEquipment from "../../assets/farmkar_assets/Png/totalequipment.png";
import complete from "../../assets/farmkar_assets/Png/complete.png";
import ongoingequipment from "../../assets/farmkar_assets/Png/ongoingequipment.png";
import { Chart } from "primereact/chart";
import { handleGetRequest } from "../../services/GetTemplate";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const Dashboard = () => {
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const [loading, setLoading] = useState(false);

    const [dashboardCount, setDashboardCount] = useState([]);
    const [dashboardReports, setDashboardReports] = useState([]);
    const [dashboardFinancialAnalytics, setDashboardFinancialAnalytics] = useState([]);
    const [allEquipments, setAllEquipments] = useState([]);
    const [equipmentFilteredData, setEquipmentFilteredData] = useState([]);
    const [equipment, setEquipment] = useState(1);

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const [chartData2, setChartData2] = useState({});
    const [chartOptions2, setChartOptions2] = useState({});

    const [chartData3, setChartData3] = useState({});
    const [chartOptions3, setChartOptions3] = useState({});

    const getActiveInActiveArray = (active) => {
        const data = [];

        for (let month = 1; month <= 12; month++) {
            const filteredItem = dashboardReports?.find((item) => item?.month === month);
            const count = filteredItem ? parseInt(filteredItem[active ? "activeCount" : "inactiveCount"]) : 0;
            data.push(count);
        }
        return data;
    };

    const chartOneMapping = () => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue("--text-color");
        const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
        const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    
        const data = {
            labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            datasets: [
                {
                    label: "Active Customer",
                    backgroundColor: "#798402",
                    borderColor: "#798402",
                    data: getActiveInActiveArray(true),
                },
                {
                    label: "Non-active Customer",
                    backgroundColor: "#db8137",
                    borderColor: "#db8137",
                    data: getActiveInActiveArray(false),
                },
            ],
        };
    
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500,
                        },
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                        precision: 0, // This will ensure that y-axis values are integers
                        callback: function (value) {
                            if (Number.isInteger(value)) {
                                return `${value}%`;  // Adding % symbol to the y-axis values
                            }
                        },
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false,
                    },
                },
            },
        };
    
        setChartData(data);
        setChartOptions(options);
    };

    const excelHandle = () => {
        const excelData = [["Month", "Active Customers", "Non-active Customers"]];

        dashboardReports.forEach((report) => {
            const month = report.month;
            const activeCount = parseInt(report.activeCount);
            const inactiveCount = parseInt(report.inactiveCount);

            excelData.push([
                getMonthName(month), // function to get month name from month number
                activeCount,
                inactiveCount,
            ]);
        });

        // Convert the Excel data array to a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(excelData);

        // Create a workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");

        // Generate Excel file and download it
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const excelBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(excelBlob, "reports.xlsx");
    };

    const getMonthName = (monthNumber) => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return months[monthNumber - 1] || "";
    };

    const allEquipmentTypesData = async () => {
        setIsActive2(true);
        const response = await dispatch(handleGetRequest(`/api/v1/equipment/categoryId/${equipment}`, true));
        if (response?.status === 200) {
            setEquipmentFilteredData(response?.data);
        }
        setIsActive2(false);
    };

    const getEquipmentArray = () => {
        const data = [];
        for (let month = 1; month <= 12; month++) {
            const filteredData = equipmentFilteredData?.filter((item) => item?.month === month).map((filteredItem) => parseInt(filteredItem?.total_requests) || 0);
            data.push(filteredData[0] || 0);
        }
        return data;
    };

    const DashboardEquipmentData = async () => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue("--text-color");
        const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
        const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
        const data = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [
                {
                    label: "Equipment Utilization",
                    backgroundColor: "#798402",
                    borderColor: "#798402",
                    data: getEquipmentArray(true),
                },
            ],
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500,
                        },
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                        precision: 0, // This will ensure that y-axis values are integers
                        callback: function (value) {
                            if (Number.isInteger(value)) {
                                return `${value}%`;  // Adding % symbol to the y-axis values
                            }
                        },
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false,
                    },
                },
            },
        };

        setChartData2(data);
        setChartOptions2(options);
    };

    const getAnalyticsData = async () => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue("--text-color");
        const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
        const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
        const data = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
            datasets: [
                {
                    label: "Analytics Record",
                    data: getFinancialAnalytics(true),
                    fill: false,
                    borderColor: "#333333",
                    tension: 0.4,
                },
            ],
        };

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                        precision: 0, // This will ensure that y-axis values are integers
                        callback: function (value) {
                            if (Number.isInteger(value)) {
                                return `${value}%`;  // Adding % symbol to the y-axis values
                            }
                        },
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
            },
        };

        setChartData3(data);
        setChartOptions3(options);
    };

    const getDashboardData = async () => {
        setIsActive(true);
        const response = await dispatch(handleGetRequest("/api/v1/equipment/dashboard", true));
        console.log("response");
        if (response?.status === 200) {
            setDashboardCount(response?.data?.dashboard[0]);
            setDashboardReports(response?.data?.activeInactive);
            setDashboardFinancialAnalytics(response?.data?.finance);
        }
        setIsActive(false);
    };

    const getFinancialAnalytics = (value) => {
        const monthMapping = {
            Jan: 1,
            Feb: 2,
            Mar: 3,
            Apr: 4,
            May: 5,
            Jun: 6,
            Jul: 7,
            Aug: 8,
            Sep: 9,
            Oct: 10,
            Nov: 11,
            Dec: 12,
        };

        const data = [];
        for (let month = 1; month <= 12; month++) {
            console.log("dashboardFinancialAnalytics", dashboardFinancialAnalytics);

            const filteredData = dashboardFinancialAnalytics?.filter((item) => monthMapping[item?.month] === month).map((filteredItem) => parseInt(filteredItem?.totalCost) || 0);

            console.log("filteredData", filteredData);

            data.push(filteredData[0] || 0);
        }

        return data;
    };

    //Get All Equipment
    const getEquipmentList = async () => {
        setIsActive(true);
        const response = await dispatch(handleGetRequest("/api/v1/equipmetCategory/getAll", true));
        if (response) {
            setAllEquipments(response?.data);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getDashboardData();
        getEquipmentList();
    }, []);

    useEffect(() => {
        if (dashboardFinancialAnalytics) {
            getAnalyticsData();
        }
    }, [dashboardFinancialAnalytics]);

    useEffect(() => {
        if (dashboardReports) {
            chartOneMapping();
        }
    }, [dashboardReports]);

    useEffect(() => {
        if (equipment) {
            allEquipmentTypesData();
        }
    }, [equipment]);

    useEffect(() => {
        if (equipmentFilteredData) {
            DashboardEquipmentData();
        }
    }, [equipmentFilteredData]);

    return (
        <>
            {loading ? (
                <>
                    <div style={{ display: "flex", justifyContent: "center", alignItem: "center", height: "70vh" }}>
                        <LoadingOverlay
                            active={isActive}
                            spinner={<Triangle height="120" width="120" color="#798403" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "rgb(38 41 51 / 78%)",
                                }),
                            }}
                        ></LoadingOverlay>
                    </div>
                </>
            ) : (
                <>
                    <div className="grid">
                        <div className="flex col-12 xl:col-12 justify-content-between custom-flex-column">
                            <div className="xl:col-4 lg:col-4 md:col-12 sm:col-12">
                                <div className="card2-border1 flex flex-column mb-3">
                                    <div className="flex flex-row justify-content-between">
                                        <div className="flex flex-column">
                                            <div className="card-number mb-3">{dashboardCount?.totalActiveEquipment}</div>
                                            <span className="block card-text mb-3 mt-1"> Total Active Equipment </span>
                                        </div>
                                        <div className={`flex align-items-center justify-content-center mb-2`}>
                                            <img src={totalEquipment} width="60px" />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="mt-2">
                                        <ProgressBar value={parseInt(dashboardCount?.totalActiveEquipment)}></ProgressBar>
                                    </div>
                                </div>
                            </div>
                            <div className="xl:col-4 lg:col-4 md:col-12 sm:col-12">
                                <div className="card2 flex flex-column mb-3">
                                    <div className="flex flex-row justify-content-between">
                                        <div className="flex flex-column">
                                            <div className="card-number mb-3">{dashboardCount?.totalCompleted}</div>
                                            <span className="block card-text mb-3 mt-1"> Completed </span>
                                        </div>
                                        <div className={`flex align-items-center justify-content-center mb-2`}>
                                            <img src={complete} width="60px" />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="mt-2">
                                        <ProgressBar value={parseInt(dashboardCount?.totalCompleted)}></ProgressBar>
                                    </div>
                                </div>
                            </div>
                            <div className="xl:col-4 lg:col-4 md:col-12 sm:col-12">
                                <div className="card2-border2 flex flex-column mb-3">
                                    <div className="flex flex-row justify-content-between">
                                        <div className="flex flex-column">
                                            <div className="card-number mb-3">{dashboardCount?.totalOnGoing}</div>
                                            <span className="block card-text mb-3 mt-1"> On-going Equipment </span>
                                        </div>
                                        <div className={`flex align-items-center justify-content-center mb-2`}>
                                            <img src={ongoingequipment} width="60px" />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="mt-2">
                                        <ProgressBar value={parseInt(dashboardCount?.totalOnGoing)}></ProgressBar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-1 main-card xl:col-12 lg:col-12 md:col-12 sm:col-12">
                        <LoadingOverlay
                            active={isActive}
                            spinner={<Triangle height="120" width="120" color="#798403" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "rgb(38 41 51 / 78%)",
                                }),
                            }}
                        >
                            <div className="flex flex-row justify-content-between">
                                <h3 style={{ color: "black" }}> Reports</h3>
                                <Button icon="pi pi-download" className="Export-Button" iconPos="right" onClick={excelHandle}>
                                    &nbsp; &nbsp; Export{" "}
                                </Button>
                            </div>
                            <Chart type="bar" className="xl:col-12 lg:col-12 md:col-12 sm:col-12" data={chartData} options={chartOptions} />
                        </LoadingOverlay>
                    </div>

                    <div className="flex col-12 custom-flex-column">
                        <div className="xl:col-6 lg:col-6 main-card md:col-12 sm:col-12 mt-4" style={{ marginRight: "5px", display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                            <LoadingOverlay
                                active={isActive2}
                                spinner={<Triangle height="120" width="120" color="#798403" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: "rgb(38 41 51 / 78%)",
                                    }),
                                }}
                            >
                                <div className="flex flex-row justify-content-between">
                                    <h3 style={{ color: "black" }} className="mb-5">
                                        {" "}
                                        Equipment Utilization
                                    </h3>
                                    <div className="custom-dropdown">
                                        <select
                                            value={equipment}
                                            onChange={(e) => setEquipment(e.target.value)}
                                            className="custom-dropdown-select"
                                            id="role"
                                            name="role"
                                        >
                                            <option value="" disabled>
                                                --Select--
                                            </option>
                                            {allEquipments.map((equipmentOption) => (
                                                <option key={equipmentOption?.id} value={equipmentOption?.id}>
                                                    {equipmentOption?.categoryName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* <Dropdown style={{ color: "white" }} placeholder="--Select--" id="role" name="role" options={allEquipments} optionLabel="categoryName" optionValue="id" value={equipment} onChange={(e) => setEquipment(e.target.value)} className="w-3 dashboard-dropdown"></Dropdown> */}
                                </div>
                                <Chart type="bar" height="50vh" className="col-12 mt-6" data={chartData2} options={chartOptions2} style={{ alignSelf: "flex-end" }} />
                            </LoadingOverlay>
                        </div>

                        <div className="xl:col-6 lg:col-6 main-card md:col-12 sm:col-12 mt-4" style={{ marginLeft: "5px", display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                            <h3 style={{ color: "black" }} className="mb-5">
                                {" "}
                                Financial Analytics{" "}
                            </h3>
                            <Chart type="line" height="50vh" className="col-12 mt-6" style={{ alignSelf: "flex-end" }} data={chartData3} options={chartOptions3} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Dashboard;
