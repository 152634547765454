import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import AddEditFarmerLenderManagement from "./AddEditFarmerLenderManagement";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { Divider } from "primereact/divider";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import deleteIcon from "../../assets/farmkar_assets/Png/delete.png";
import editIcon from "../../assets/farmkar_assets/Png/edit.png";
import viewIcon from "../../assets/farmkar_assets/Png/view.png";
import { hooks } from "prismjs";
import CustomDeleteDialog from "../../components/CustomDeleteDialog";
import { baseURL } from "../../Config";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";
import { Calendar } from "primereact/calendar";
import moment from "moment";

const FarmerLenderManagement = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(false);
    const [userRowData, setUserRowData] = useState();
    const [addEditUser, setAddEditUser] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [viewDisable, setViewDisable] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [date, setDate] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="">
                <Button className="view-icon" onClick={() => handleViewUser(rowData)}>
                    <img src={viewIcon} alt="View" />
                </Button>
                {/* <Button className="edit-icon ml-2" onClick={() => handleEditUsers(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button> */}
                {/* <Button className="del-icon" onClick={() => confirm(rowData)}>
                    <img src={deleteIcon} alt="Delete" />
                </Button> */}
            </div>
        );
    };

    const confirm = (rowData) => {
        const acceptAction = () => handleDeleteUser(rowData);
        confirmDialog({
            message: <CustomDeleteDialog message="Are you sure you want to delete this record?" acceptAction={acceptAction} />,
            header: "",
            accept: acceptAction,
            reject: null,
            draggable: false,
        });
    };

    const handleDeleteUser = async (rowData) => {
        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(`/api/user/delete/${rowData?._id}`, true, true));
        if (response?.status === 200) {
            // await getUserList()
        } else if (response?.status === 403) {
            window.localStorage.clear();
            history.push("/");
            toast.info("Please Login again");
        }

        setIsActive(false);
    };

    const nameTemplate = (rowData) => {
        const farmerName = `${rowData?.equipmentofFarmerName}`;
        const lenderName = `${rowData?.requesterFarmerName}`;

        return (
            <>
                <div className="flex flex-column align-items-start" style={{ marginLeft: "30px" }}>
                    <span className="mb-3">
                        {farmerName}
                        <span style={{ fontSize: "0.7rem" }}>{`(Farmer)`} </span>
                    </span>
                    <i className="pi pi-arrow-down mb-3 ml-1" style={{ fontSize: "1.2rem", color: "#8080806e" }}></i>
                    <span>
                        {lenderName}
                        <span style={{ fontSize: "0.7rem" }}>{`(Lender)`} </span>
                    </span>
                </div>
            </>
        );
    };

    const statusTemplate = (rowData) => {
        const status = rowData?.statusName;
        return <Tag style={{ fontSize: "11px", padding: "3px 6px", background: status == "Completed" ? "#f59e0b" : null }} value={status} severity={status === "Pending" ? "info" : status == "Recieved" ? "success" : status == "Accepted" ? "secondary" : "danger"}></Tag>;
        // return <div style={{ color: status === "On-going" ? "#db8137" : status == "Completed" ? "#798403" : status == "Accepted" ? "#169de0" : "#FF0000" }}>{status}</div>;
    };

    const handleEditUsers = (rowData) => {
        history.push({
            pathname: `/editViewFarmers`,
            state: { userRowData: rowData, addEditUser: true },
        });
    };

    const handleViewUser = (rowData) => {
        history.push({
            pathname: `/editViewFarmers`,
            state: { userRowData: rowData, addEditUser: true, viewDisable: true },
        });
    };

    //Get All Users
    // const getFarmerLenderList = async () => {
    //     setIsActive(true);
    //     const response = await dispatch(handleGetRequest("/api/v1/equipmentReq/farmerLender", true));
    //     if (response) {
    //         setAllUsers(response?.data);
    //     }
    //     setIsActive(false);
    // };

    const getFarmerLenderList = async (selectedDate = null) => {
        setIsActive(true);

        // If a date is selected, manually format it to YYYY-MM-DD format
        const formattedDate = selectedDate ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, "0")}-${selectedDate.getDate().toString().padStart(2, "0")}` : "";

        const response = await dispatch(handleGetRequest(`/api/v1/equipmentReq/farmerLender${formattedDate ? `?date=${formattedDate}` : ""}`, true));

        if (response) {
            setAllUsers(response?.data?.data ? response?.data?.data : response?.data);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getFarmerLenderList();
    }, []);

    // Call getFarmerLenderList whenever the user selects a date from the calendar
    useEffect(() => {
        if (date) {
            getFarmerLenderList(date); // API call with selected date
        }
    }, [date]);

    const imageTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Image src={`${baseURL}/${rowData?.equipment?.images[0]?.images}`} zoomSrc={`${baseURL}/${rowData?.equipment?.images[0]?.images}`} alt="Image" preview />
            </React.Fragment>
        );
    };

    const Header = () => {
        return (
            <>
                <h3 className="pr-2 pl-2 text-center" style={{ fontWeight: "600" }}>
                    {addEditUser && !viewDisable ? "Edit User" : viewDisable && addEditUser ? "View Details" : "Add User"}
                </h3>
                <div className="mt-2">
                    <hr />
                </div>
            </>
        );
    };

    const formatDuration = (days, hours) => {
        // Handle days
        const daysText = days == 1 ? "1 day" : `${days} days`;

        // Handle hours
        const hoursText = hours == 1 ? "1 hour" : `${hours} hours`;

        // Combine days and hours
        if (days > 0 && hours > 0) {
            return `${daysText} ${hoursText}`;
        } else if (days > 0) {
            return daysText;
        } else if (hours > 0) {
            return hoursText;
        } else {
            return "N/A"; // Fallback text if no days or hours
        }
    };

    return (
        <>
            <div className="card dark-bg">
                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle height="120" width="120" color="#798403" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "rgb(38 41 51 / 78%)",
                        }),
                    }}
                >
                    <DataTable
                        header={
                            <>
                                <div className="flex justify-content-between custom-alignment" style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #ccc" }}>
                                    <div>
                                        <span className="search-container">
                                            <i className={globalFilterValue ? "" : "pi pi-search search-icon"} />
                                            <InputText
                                                placeholder="Search"
                                                value={globalFilterValue}
                                                onChange={onGlobalFilterChange}
                                                className="search-input mb-3"
                                            />
                                        </span>
                                    </div>
                                    <div id="date" className="">
                                        <Calendar
                                            maxDate={new Date()} // Disable future dates
                                            placeholder="--Select Date--"
                                            id="buttondisplay"
                                            className="mb-2"
                                            value={date}
                                            onChange={(e) => setDate(e.value)} // Update state when a new date is selected
                                            showIcon
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        responsive={true}
                        filters={filters}
                        globalFilterFields={["equipmentEnglishName", "equipmentFueltype", "equipmentModel", "equipmentTotalCost", "equipmentofFarmerName", "requesterFarmerName", "statusName"]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={allUsers}
                        emptyMessage="No records found"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column body={nameTemplate} header="Name"></Column>
                        {/* <Column body={imageTemplate} header="Image"></Column> */}
                        <Column field="equipmentEnglishName" header="Equipment Type"></Column>
                        <Column field="equipmentFueltype" header="Fuel Type"></Column>
                        <Column field="equipmentModel" header="Model"></Column>
                        <Column field="equipmentTotalCost" header="Payment"></Column>
                        <Column
                            field="totalDays"
                            header="Duration"
                            body={(rowData) => {
                                const days = rowData?.equipmentRequestTotalDays;
                                const hours = rowData?.equipmentRequestTotalHours;
                                return formatDuration(days, hours); // Calling the function here
                            }}
                        ></Column>
                        <Column field="payment_method" header="Payment Method"></Column>
                        <Column
                            field="equipmentRequestAddress"
                            header="Location"
                            body={(rowData) => {
                                const address = rowData?.equipmentRequestAddress || "";
                                const parts = address.split(","); // Split by comma
                                return parts.slice(0, 2).join(", "); // Take the first 2 parts and join
                            }}
                        ></Column>
                        <Column body={(rowData) => moment(rowData?.equipmentRequestCreatedDate).format('DD-MM-YYYY')} header="Date"></Column>
                        <Column body={statusTemplate} header="Status"></Column>
                        <Column header="Action" body={actionTemplate} />
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default FarmerLenderManagement;
