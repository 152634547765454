import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import "./AppTopbar.scss";
import appLogo from '../src/assets/farmkar_assets/Png/logo.png'

export const AppTopbar = (props) => {

    const op = useRef(null);
    const location = useLocation()
    const history = useHistory();
    const firstName = localStorage.getItem("firstName")
    const lastName = localStorage.getItem("lastName")

    const items = [
        {
            label: "Logout",
            command: () => {
                window.localStorage.clear();
                history.push("/");
            },
        },
    ];

    const handleLogOut = () => {
        window.localStorage.clear();
        history.push("/");
    }

    return (

        <>
            <div className="layout-topbar">

                <Link to="/dashboard" className="layout-topbar-logo">
                    <img src={appLogo} alt="App-logo" width='60px' height='60px' />
                    <div className="flex flex-column ml-2 text-center topbar-heading">
                        <span style={{ fontWeight: "bold", fontSize: "22px", color: "#db8137" }}>FARM KAR</span>
                    </div>
                </Link>

                <button type="button" className="p-link layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick} style={{ marginLeft: "20px" }}>
                    <i className="pi pi-bars" />
                </button>

                <div style={{ textAlign: "center", width: "100%" }}>
                    <p style={{ color: "#A6AC57", fontWeight: "600", fontSize: "22px" }}>{location?.pathname == '/dashboard' ? 'Dashboard' : location?.pathname == '/users' ? 'User Management' : location?.pathname == '/customers' ? 'Customer Management' : location?.pathname == '/farmers' ? 'Farmer/ Lender Management' : location?.pathname == '/equipments' ? 'Equipment Management' : location?.pathname == '/common' ? 'Equipment/ Fuel Types' : null}</p>
                </div>

                <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                    <i className="pi pi-ellipsis-v" />
                </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick} style={{ width: "100%" }}>
                            <label style={{ fontStyle: "italic", fontWeight: "600" }} >
                                Hello, {firstName} {lastName}
                            </label>
                        </button>
                    </li>

                    {/* <li className="flex">
                        <div className="">
                                <h2 className="">
                                    <Badge value={`Hello, ${firstName} ${lastName}`} severity="warning" style={{ fontSize: '0.89rem' }} />
                                </h2>
                            </div>

                        <SplitButton model={items} className="p-button-text custom-button-css ml-4"></SplitButton>
                        <button className="p-link layout-topbar-button user-image" type="button" onClick={() => {
                            handleLogOut();
                        }}>
                            <i className="pi pi-power-off" title="Logout"></i>
                        </button>
                    </li> */}

                </ul>
            </div>
        </>
    );
};
