import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { handlePutRequest } from "../../services/PutTemplate";
import { useHistory, useLocation } from "react-router-dom";
import { Rating } from "primereact/rating";
import moment from "moment";
import { Divider } from "primereact/divider";
import { Triangle } from "react-loader-spinner";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";

const AddEditFarmerLenderManagement = ({ onHide, getUserList }) => {

    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [statusValue, setStatusValue] = useState('');
    const [objectId, setObjectId] = useState('');
    const [statusOptions, setStatusOptions] = useState([]);
    const location = useLocation();

    const [pendingRecord, setPendingRecord] = useState("");
    const [onGoingRecord, setOnGoingRecord] = useState("");
    const [acceptedRecord, setAcceptedRecord] = useState("");
    const [completedRecord, setCompletedRecord] = useState("");
    const [receivedRecord, setReceivedRecord] = useState("");
    const [rejectRecord, setRejectRecord] = useState("");
    const [returnRecord, setReturnRecord] = useState("");
    const [statusDialog, setStatusDialog] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const userId = localStorage.getItem('userId')

    const userRowData = location.state.userRowData;
    const addEditUser = location.state.addEditUser;
    const viewDisable = location.state.viewDisable;

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {},

        onSubmit: async (data) => { },
    });

    //Get Record By Id
    const getRecordById = async () => {
        setIsActive(true);
        // const response = await dispatch(handleGetRequest(`/api/v1/equipmentReq/details/${userRowData?.id}`, true));
        // const filteredData = userRowData;
        const pending = userRowData?.statusName == "Pending";
        const ongoing = userRowData?.statusName == "On Going";
        const accepted = userRowData?.statusName == "Accepted";
        const completed = userRowData?.statusName == "Completed";
        const received = userRowData?.statusName == "Recieved";
        const rejected = userRowData?.statusName == "Reject";
        const returnn = userRowData?.statusName == "Return";

        if (pending) {
            setPendingRecord(pending);
        }
        if (ongoing) {
            setOnGoingRecord(ongoing);
        }
        if (accepted) {
            setAcceptedRecord(accepted);
        }
        if (completed) {
            setCompletedRecord(completed);
        }
        if (received) {
            setReceivedRecord(received);
        }
        if (rejected) {
            setRejectRecord(rejected);
        }
        if (returnn) {
            setReturnRecord(returnn);
        }

        setIsActive(false);
    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getRecordById();
        }
    }, [userRowData, addEditUser]);

    const handleStatusView = (item, userRowData) => {
        setStatusValue(item?.equipmentRequestStatusId)
        setObjectId(userRowData?.equipmentRequestId)
        setStatusDialog(true)
    }

    const getAllStatus = async () => {
        const response = await dispatch(handleGetRequest(`/api/v1/status/getAll`, true));
        if (response?.status == 200) {
            setStatusOptions(response?.data)
        }
    }

    const onHide2 = () => {
        setStatusDialog(false)
    }

    useEffect(() => {
        getAllStatus()
    }, [])

    const handleStatusUpdate = async () => {
        setSaveBtnLoading(true)
        const obj = {
            userId: userId,
            status: statusValue,
        }

        const response = await dispatch(handlePutRequest(obj, `/api/v1/equipmentReq/update/${objectId}`, true));
        if (response?.status == 200) {
            onHide2()
            history.push('/farmers')
        }
        setSaveBtnLoading(false)
    }

    return (
        <>
            <Dialog draggable={false} resizable={false} header='Update Status' visible={statusDialog} style={{ width: "40vw" }} onHide={onHide2}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="col-12 fields-display justifsy-content-evenly">
                        <div className="col-12 flex flex-column md:col-6">
                            <div className="flex flex-row">
                                <label> Status </label>
                                <span className="Label__Required">*</span>
                            </div>
                            <Dropdown placeholder="--Select--" options={statusOptions} optionLabel="name" optionValue="id" className="p-inputtext-sm mt-2" id="statusValue" name="statusValue" value={statusValue} onChange={(e) => setStatusValue(e.value)} />
                        </div>
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button mr-1 mb-2" label="Cancel" type="button" onClick={onHide2} />
                        <Button className="Save-Button ml-1 mb-2" label="Update" loading={saveBtnLoading} type="button" onClick={handleStatusUpdate} />
                    </div>
                </div>
            </Dialog>

            <LoadingOverlay
                active={isActive}
                spinner={<Triangle height="120" width="120" color="#798403" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgb(38 41 51 / 78%)",
                    }),
                }}
            >
                <div>

                    <div className="equipment-card">
                        <h4 className="farmer-heading">Equipment Details</h4>
                        {userRowData?.statuses?.map((item, index) => {
                            const isLastItem = index === userRowData.statuses.length - 1;
                            return (

                                <div className="p-fluid formgrid pl-2 pr-2 equipment-card">
                                    <hr />
                                    <div className="ml-4 mt-3 mr-4 grid col-12">
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Farmer Name </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentofFarmerName}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Lender Name </label>
                                            </div>
                                            <span className="input-field">{userRowData?.requesterFarmerName}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Equipment Name </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentEnglishName}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Equipment Type </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentFueltype}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Model </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentModel}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Total Cost </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentTotalCost}</span>
                                        </div>
                                    </div>

                                    <div className="ml-4 mt-3 mr-4 grid col-12">
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Equipment Category </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentCategoryName}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Equipment Charges Type </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentChargesName}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Equipment Address </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentAddress}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Latitude </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentLatitude}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Longitude </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentLongitude}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Equipment Per day charges </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentPerDayCharges || "N/A"}</span>
                                        </div>
                                    </div>

                                    <div className="ml-4 mt-3 mr-4 grid col-12">
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Equipment Per hour Charges </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentPerHourCharges || "N/A"}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Lender Address </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentRequestAddress}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Request Date </label>
                                            </div>
                                            <span className="input-field">{moment(item?.statusLogCreatedDate).format("Do MMM, YYYY")}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Request from date </label>
                                            </div>
                                            <span className="input-field">{moment(userRowData?.equipmentRequestFromDate).format("Do MMM, YYYY")}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Request to date </label>
                                            </div>
                                            <span className="input-field">{moment(userRowData?.equipmentRequestToDate).format("Do MMM, YYYY")}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Request Total Days </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentRequestTotalDays}</span>
                                        </div>
                                    </div>

                                    <div className="ml-4 mt-3 mr-4 grid col-12">
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Request Total Hours </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentRequestTotalHours || "N/A"}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Equipment Request Total Routes </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentRequestTotalRoutes}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Request updated date </label>
                                            </div>
                                            <span className="input-field">{moment(userRowData?.equipmentRequestUpdatedDate).format("Do MMM, YYYY")}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Farmer phone no. </label>
                                            </div>
                                            <span className="input-field">{userRowData?.equipmentofFarmerPhoneNo}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Lender phone no. </label>
                                            </div>
                                            <span className="input-field">{userRowData?.requesterFarmerPhoneNo}</span>
                                        </div>
                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Average Rating </label>
                                            </div>
                                            <span className="input-field">{userRowData?.avgRating} Star</span>
                                        </div>

                                        <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                            <div className="flex flex-row">
                                                <label className="label-field mb-2"> Current Status </label>
                                            </div>
                                            <span className="input-field">
                                                <div
                                                    onClick={() => isLastItem && handleStatusView(item, userRowData)}
                                                    style={{ cursor: isLastItem ? 'pointer' : '' }}>
                                                    <Tag
                                                        style={{
                                                            fontSize: "11px",
                                                            padding: "3px 6px",
                                                            background: item?.equipmentRequestStatusName === "Accepted" ? "gray" :
                                                                item?.equipmentRequestStatusName === "Completed" ? "#f59e0b" : null
                                                        }}
                                                        value={item?.equipmentRequestStatusName}
                                                        severity={item?.equipmentRequestStatusName === "Pending" ? "info" :
                                                            item?.equipmentRequestStatusName === "Recieved" ? "success" : "danger"}
                                                    />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </LoadingOverlay>
        </>
    );
};

export default AddEditFarmerLenderManagement;
